<template>
  <!-- Display a payment form -->
  <div class="text-center mb-6 mt-6" style="width: 95%; margin: auto;">
    <v-alert v-if="error.length > 0" type="error">{{ error }}</v-alert>
    <div v-show="step == 0">
      <v-row>
        <v-col>
          <h2>Payment information</h2>
        </v-col>
      </v-row>
      <v-form ref="payment_form">
        <v-row>
          <v-col>
            <div v-if="contract_id.length > 0">Contract # {{ contract_id }}</div>
            <div v-else>No contract ID</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field-outlined v-model="name" label="Full name" required></text-field-outlined>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=4>
            <select-outlined :disabled="guest && guestWithAmount" :items="currency_list" v-model="currency" label="Currency"></select-outlined>
          </v-col>
          <v-col cols=4>
            <text-field-outlined :disabled="guest && guestWithAmount" v-model="deposit_amount" :label="'Deposit'" :suffix="'' + currency + ''"
              type="number"></text-field-outlined>
          </v-col>
          <v-col cols=4>
            <text-field-outlined :disabled="guest && guestWithAmount" v-model="payment_amount_pre_tip" :label="'Payment'" :suffix="'' + currency + ''"
              type="number"></text-field-outlined>
          </v-col>
        </v-row>
        <v-row v-show="!guest">
          <v-col cols=6> <v-switch v-model='sa' label="SA"></v-switch></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn v-if="!guest" @click="share()">Share</v-btn> <v-btn color="primary" @click="go_tip_or_skip()">Next</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-show="step == 1">
      <v-row>
        <v-col>
          <h2>Would you like to add a tip?</h2>
        </v-col>
      </v-row>
      <v-form ref="tip_form">
      <v-item-group mandatory  class="pb-3">
        <v-container>
          <v-row>
            <v-col
              v-for="n in [0, 5, 10, 15, 20,25]"
              :key="n"
              cols="12"
              md="4" xs="6"
            >
              <v-item v-slot="{ active, toggle }">
                <v-card
                  :color="active?'primary':''"
                  class="d-flex align-center p-1"
                  dark
                  @click="toggle(); tip=n"
                >
                  <v-scroll-y-transition>
                    <div
                      color="primary"
                      class="text-h5 flex-grow-1 text-center"
                    >
                      {{ n > 0 ? n+"%": "No tip" }}
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
        <v-row class="p-3">
          <v-col cols=4>
            <h6 class="text-left">Amount:</h6>
            <h6 class="text-left">Tip:</h6>
            <h6 class="text-left">Total:</h6>
            <span v-if="deposit_amount > 0"><h6 class="text-left">Deposit amount:</h6></span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols=4>
            <h6 class="text-right">{{ payment_amount_pre_tip_fixed }} {{ currency }}</h6>
            <h6 class="text-right">{{ tip_amount }} {{ currency }}</h6>
            <h6 class="text-right">{{ payment_amount }} {{ currency }}</h6>
            <span v-if="deposit_amount > 0"><h6 class="text-right">{{ deposit_amount_fixed }} {{ currency }}</h6></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" @click="step=0">Previous</v-btn> <v-btn v-if="!guest" @click="share()">Share</v-btn> <v-btn color="primary" @click="get_client_secret()">Next</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-show="step == 2">
      <v-row>
        <v-col>
          <h2>Card details</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form id="payment-form">
            <div id="linkauthenticationelement">
              <!--Stripe.js injects the Link Authentication Element-->
            </div>
            <div id="paymentelement">
              <!--Stripe.js injects the Payment Element-->
            </div>
          </form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="step = 0" id="previous">
            <span id="button-text">Return</span>
          </v-btn>
          <v-btn id="submit" @click="submit_payment()" color="primary" :loading="buttonLoading">
            <span id="button-text">Process payment</span>
          </v-btn>
          <div id="payment-message" class="hidden"></div>
        </v-col>
      </v-row>
    </div>
    <div v-show="step == 3">
      <v-row>
        <v-col>
          <h2>{{ validation_message }}</h2>
        </v-col>
      </v-row>
      <v-row><v-col>
        <h3>{{ validation_message_deposit }}</h3>
      </v-col></v-row>
      <v-row>
        <v-col>
          <v-btn @click="error='';step = 0" id="previous">
            <span id="button-text" color="primary">Start over</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DataService from "../services/DataService";
import { loadStripe } from "@stripe/stripe-js";
export default {

  name: 'Payments',
  components: {
  },
  data: function () {
    return {
      step: 0,
      currency_list: ['CHF', 'EUR'],
      currency: 'CHF',  // Default currency
      payment_amount_pre_tip: 0.0,
      deposit_amount: 0.0,
      contract_id: '',
      name: '',
      email: '',
      customer_id_sa: '',
      customer_id_in: '',
      error: '',
      guest: false,
      guestWithAmount: false,
      sa: true,
      client_secret: null,
      stripe: null,
      elements: null,
      validation_message: '',
      validation_message_deposit: '',
      tip:0,
      buttonLoading:false,
    }
  },

  beforeDestroy() {
  },

  mounted() {
    if ("contractnumber" in this.$route.params && this.$route.params.contractnumber !== undefined) {
      this.contract_id = this.$route.params.contractnumber;
    }
    if ("name" in this.$route.params && this.$route.params.name !== undefined) {
      this.name = this.$route.params.name;
    }
    if ("value" in this.$route.params && this.$route.params.value !== undefined) {
      this.payment_amount_pre_tip = this.$route.params.value;
      if (this.payment_amount_pre_tip > 0)
        this.guestWithAmount = true;
    }
    if ("currency" in this.$route.params && this.$route.params.currency !== undefined) {
      this.currency = this.$route.params.currency;
    }
    if ("guest" in this.$route.params && this.$route.params.guest !== undefined) {
      this.guest = Boolean(this.$route.params.guest);
    }
    if ("sa" in this.$route.params && this.$route.params.sa !== undefined) {
      this.sa = this.$route.params.sa;
    }
    if ("deposit" in this.$route.params && this.$route.params.deposit !== undefined) {
      this.deposit_amount = this.$route.params.deposit;
      if (this.deposit_amount > 0)
        this.guestWithAmount = true;
    }

  },
  computed: {
    payment_amount: function () {
      return (this.payment_amount_pre_tip_fixed * (1+parseFloat(this.tip)*0.01)).toFixed(2)
    },
    payment_amount_pre_tip_fixed: function () {
      const amount = parseFloat(this.payment_amount_pre_tip);
      return isNaN(amount) ? '0.00' : amount.toFixed(2);
    },
    deposit_amount_fixed: function () {
      const amount = parseFloat(this.deposit_amount);
      return isNaN(amount) ? '0.00' : amount.toFixed(2);
    },
    tip_amount: function () {
      return (parseFloat(this.payment_amount_pre_tip) * parseFloat(this.tip)*0.01).toFixed(2)
    }
  },
  methods: {

    go_tip_or_skip() {
      // If there is no payment amount, only deposit go to step 2
      if (this.payment_amount_pre_tip_fixed == 0) {
        this.step = 2;
        this.get_client_secret();
      }
      else {
        this.step = 1;
      }
    },

    async get_client_secret() {
      this.error = '';
      // Step 1 of payment, post-tip
      const post_input = {
        payment_amount: this.payment_amount,
        deposit_amount: this.deposit_amount_fixed,
        currency: this.currency,
        name: this.name,
        contract_id: this.contract_id,
        customer_id_sa: this.customer_id_sa,
        customer_id_in: this.customer_id_in,
        is_sa: this.sa,
      }
      var returned = await DataService.getClientSecret(post_input)
      if (returned.data.error) {
        this.error = returned.data.message
      }
      else {
        // No error, go to step 2 of payment
        this.error = ''
        this.client_secret = returned.data[0];
        this.initialize_stripe_js();
        this.step = 2;
      }
    },

    async initialize_stripe_js() {
      this.error = '';
      this.step = 2;
      this.stripe = await loadStripe(this.client_secret.public_key, { stripeAccount: this.client_secret.connected_account_id });
      let elements;
      const appearance = {
        theme: 'flat',
      };
      this.elements = this.stripe.elements({ clientSecret: this.client_secret.client_secret, locale: "en", appearance });

      //const linkAuthenticationElement = this.elements.create("linkAuthentication");
      //linkAuthenticationElement.mount("#linkauthenticationelement");

      const paymentElementOptions = {
        layout: "tabs",
        fields: {
          billingDetails: {
            email: 'never',
          }
        }
      };

      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount("#paymentelement");
    },

    async submit_payment() {

      if (this.stripe == null) {
        console.log("Stripe not initialized")
        return;
      }
      this.buttonLoading = true;
      var elements = this.elements;
      const { error } = await this.stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "",
          payment_method_data: {
            billing_details: {
              name: this.name,
              email: this.email,
            }
          }
          //receipt_email: emailAddress,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error == null) {
        console.log("check status")
        this.check_status();
      } else {
        this.buttonLoading = false;
        if (error.type === "card_error" || error.type === "validation_error") {
          this.error = error.message;
        } else {
          this.error = "An unexpected error occurred. Error: " + error.type + " " + error.message;
        }
      }
    },

    share() {
      var params = { value: this.payment_amount_pre_tip, currency: this.currency, name: this.name, guest: 1, sa: +this.sa, deposit: +this.deposit_amount, contractnumber: this.contract_id }
      if (this.payment_amount_pre_tip == '')
      {
        delete params.value;
      }
      if (this.deposit_amount_fixed == '')
      {
        delete params.deposit;
      }
      if (this.name == '')
      {
        delete params.name;
      }
      if (this.contract_id == '')
      {
        delete params.contractnumber;
      }
      var route = this.$router.resolve({
        name: 'payments',
        params: params
      })
      var url = new URL(route.href, window.location.origin).href;
      console.log(this.$router)
      console.log(route)
      console.log(url)
      if (navigator.share) {
        navigator.share({ title: "Riviera Rentals Payment", url: url });
      }
      else {
        window.location.replace(url);
      }
    },

    async check_status() {
      if (this.stripe == null) {
        console.log("No client information stored")
        return;
      }

      let clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        clientSecret = this.client_secret.client_secret;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.validation_message = "Payment succeeded.";
          break;
        case "requires_capture":
          this.validation_message = "Authorization succeeded.";
          break;
        case "processing":
          this.validation_message = "Your payment is processing.";
          break;
        case "requires_payment_method":
          this.validation_message = "Your payment was not successful, please try again.";
          break;
        default:
          this.validation_message = "Something went wrong. Status:" + paymentIntent.status;
          break;
      }


      // Get payment secret to see if there is a deposit to charge
      if (this.client_secret.deposit_after_payment && Number(this.client_secret.deposit_after_payment) > 0) {
          
        this.validation_message += " Processing deposit... wait 3 seconds";
        // Wait 3 seconds before continuing
        await new Promise(resolve => setTimeout(resolve, 3000));

        let to_send = {
          amount: Number(this.client_secret.deposit_after_payment) / 100.0,
          id: paymentIntent.id,
          is_sa: this.sa,
          deposit: true,
          payment_method: paymentIntent.payment_method,
          customer_id: this.client_secret.customer_id,
        }


        DataService.paymentCharge(to_send).then(
          async response => {
            if (response.data && response.data.length > 0) {
              if (!response.data[0].error) {
                var message = response.data[0].message;
                if (message == "requires_capture")
                {
                  this.validation_message_deposit = "Deposit authorized."
                }
                else
                {
                  const { paymentIntent, error } = await this.stripe.confirmCardPayment(response.data[0].client_secret);
                  
                  if (error) {
                    this.validation_message_deposit = "Deposit not authorized! Need 3DSecure."

                  } else {
                    this.validation_message_deposit = "Deposit authorized using 3D Secure."
                  }
                }

              } else {
                console.log("Deposit error : " + response.data[0].message);
                this.validation_message_deposit = "Deposit error : " + response.data[0].message;
              }
            } else {
              console.log("Deposit error: No data received");
              this.validation_message_deposit = "Deposit error: No data received";
            }
          }
        ).catch(error => {
          console.log("Deposit error: Request failed", error);
          this.validation_message_deposit = "Deposit error: Request failed";
        });
      }

      this.buttonLoading = false;
      this.step = 3;
    }

  }
}
</script>
