import http from "../http-common";
var optionAxios = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

class DataService {

  getcars() {
    return http.get('/getcars');
  }

  getavailablecars() {
    return http.get('/getavailablecars');
  }

  getcontracts() {
    return http.get('/getcontracts');
  }

  getcontracts24months() {
    return http.get('/getcontracts/48');
  }

  getclients() {
    return http.get('/getclients');
  }

  getpayments() {
    return http.get('/payments/get');
  }

  getcontract(id: any) {
    return http.get(`/getcontract/${id}`);
  }

  getcontractfromcontractnumber(contract_number:any) {
    return http.get(`/getcontractfromcontractnumber/${contract_number}`);
  }

  deletecontract(id: any) {
    return http.get(`/deletecontract/${id}`);
  }

  login(postload: any) {
    return http.post(`/login`, postload);
  }

  pay(contract_uuid: any, amount: any) {
    return http.get(`/pay/${contract_uuid}/${amount}`);
  }

  sign(contract_uuid: string, post_payload: any) {
    return http.post(`/sign/${contract_uuid}`, post_payload);
  }

  getclient(id: any) {
    return http.get(`/getclient/${id}`);
  }

  searchclient(name: any) {
    return http.get(`/searchclient/${name}`);
  }

  addclient(post_payload: any) {
    return http.post('/addclient', post_payload);
  }

  addcontract(post_payload: any) {
    return http.post('/addcontract', post_payload);
  }

  updatecars(post_payload: any) {
    return http.post('/updatecars', post_payload);
  }

  deletecar(id: any) {
    return http.delete(`/deletecar/${id}`);
  }

  getClientSecret(post_payload: any) {
    return http.post(`/payment/clientsecret`, post_payload);
  }

  paymentCapture(post_payload) {
    return http.post(`/payment/capture`, post_payload);
  }

  paymentCancel(post_payload) {
    return http.post(`/payment/cancel`, post_payload);
  }

  paymentCharge(post_payload) {
    return http.post(`/payment/charge`, post_payload);
  }

  changePassword(post_payload) {
    return http.post(`/user/password`, post_payload);
  }

  getUsers() {
    return http.get(`/user`);
  }

  addUser(post_payload) {
    return http.post(`/user`, post_payload);
  }

  delUser(id) {
    return http.delete(`/user/${id}`);
  }

  updateUser(post_payload) {
    return http.put(`/user`, post_payload);
  }

}

export default new DataService();

